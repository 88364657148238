<template>
  <button
    type="button"
    class="btn btn-default btn-bold btn-upper btn-font-sm"
    @click="onButtonClicked()"
  >
    <i :class="buttonIcon"></i>
    {{ buttonText }}
  </button>
</template>

<script>
export default {
  props: {
    buttonText: { type: String },
    buttonName: { type: String },
    buttonIcon: { type: String },
    onButtonModalClicked: { type: Function }
  },
  methods: {
    onButtonClicked: function() {
      $("#" + this.buttonName).modal({
        keyboard: false,
        backdrop: "static"
      });

      if (this.onButtonModalClicked != null) {
        this.onButtonModalClicked();
      }
    }
  }
};
</script>
