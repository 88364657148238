<template>
  <div class="kt-portlet__head-label">
    <h3 class="kt-portlet__head-title">{{ headTitle }} <small>{{ subTitle ? subTitle : '' }}</small></h3>
  </div>
</template>

<script>
export default {
    props: {
        headTitle: { type: String },
        subTitle: { type: String, default: null }
    }
};
</script>

