<template>
  <div class="text-center">
    <template v-if="checkPermission('WorkOrderPICResource.PUT.WorkOrderPIC')">
      <button
        @click="editGallery()"
        class="btn btn-sm btn-clean btn-icon btn-icon-md"
        title="Edit"
      >
        <i class="la la-pencil-square"></i>
      </button>
    </template>
    <template v-if="checkPermission('WorkOrderPICResource.DELETE.WorkOrderPIC.id')">
      <button
        @click="deleteGallery()"
        class="btn btn-sm btn-clean btn-icon btn-icon-md"
        title="Hapus"
      >
        <i class="la la-trash"></i>
      </button>
    </template>
  </div>
</template>

<script>
import CheckPermission from "../../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import SweetAlertTemplate from "./../../_general/SweetAlert";
import BlockUI from "../../_js/BlockUI";
const blockUI = new BlockUI();
import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
const PhotoRepository = RepositoryFactory.get("photo");
const SweetAlert = Vue.extend(SweetAlertTemplate);

export default {
  props: {
    editId: {},
    rowData: { type: Object }
  },
  methods: {
    deleteGallery() {
      var vx = this;

      let instance = new SweetAlert().showConfirm({
        swalTitle: "Apakah Anda yakin?",
        swalText: "Data Gambar akan terhapus.",
        swalType: "warning",
        onConfirmButton: function () {
          blockUI.blockPage();
          vx.delete();
        },
        onCancelButton: function () {
          blockUI.unblockPage();
        }
      });
    },
    async delete() {
      var vx = this;

      await PhotoRepository.deletePhoto(this.editId)
        .then(response => {
          new Promise((resolve, reject) => {
            vx.$emit("event", resolve);
          }).then((response) => {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Data Gambar berhasil terhapus.",
              swalType: "success",
              onConfirmButton: function () {
                blockUI.unblockPage();
              }
            });
          });
        })
        .catch(error => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: "Data Gambar gagal terhapus.",
            swalType: "error",
            onConfirmButton: function () {
              blockUI.unblockPage();
            }
          });
        });
    },
    async editGallery() {
      await PhotoRepository.getPhoto(this.editId)
        .then(response => {
          this.$emit("edit", response.data);
        })
        .catch(error => {
        });
    },
    checkPermission: function (method) {
      return checkPermission.isCanAccess(method);
    }
  }
};
</script>
