<template>
  <div class="row h-100">
    <div class="col-3 mt-auto">
      <span class="kt-switch kt-switch--sm">
        <label class="mb-0">
          <input
            type="checkbox"
            v-model="data_auto_refresh"
          >
          <span></span>
        </label>
      </span>
    </div>
    <div class="col-9 my-auto" v-show="isVisible">
      <label class="col-form-label float-right">{{ $t('button.autoRefresh') }}
        <template v-if="data_auto_refresh">
        ({{ counter }})
        </template>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data_auto_refresh: false,
      timer: null,
      countDown: 10,
      counter: 10
    };
  },
  props: {
    tableName: { type: String },
    onRefresh: { type: Function },
    autoRefreshState: { type: Boolean, default: false },
    isVisible: { type: Boolean, default: true }
  },
  watch: {
    data_auto_refresh:  function (newValue, oldValue) {
      try {
        if (newValue) {
          let isDone = false
          this.timer = setInterval(
            async function () {
              try {
                if (this.counter == 0) {
                    if (!isDone) {
                      isDone = true
                      await this.onRefresh();
                      this.counter = this.countDown;
                    }
                      isDone = false
                } else {
                  this.counter--;
                }
              } catch (error) {
                this.counter = this.countDown;
                toastr.error("Refresh error");
              }
            }.bind(this),
            1000
          );
        } else {
          clearInterval(this.timer);
          this.counter = this.countDown;
        }
      } catch(error) {
        console.log(error , "data_auto_refresh error");
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.counter = this.countDown;
  },
  mounted() {
    this.data_auto_refresh = this.autoRefreshState;
  }
};
</script>
