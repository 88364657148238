<template>
  <div>
    <div
      class="blockUI blockOverlay"
      style="z-index: 100; border: none; margin: 0px; padding: 0px; width: 100%; height: 100%; top: 0px; left: 0px; background-color: rgb(0, 0, 0); opacity: 0.5; cursor: wait; position: fixed;"
    ></div>
    <div
      class="blockUI blockMsg blockPage"
      style="z-index: 1011; position: fixed; padding: 0px; margin: 0px; width: 187px; top: 50%; left: 50%; text-align: center; color: rgb(0, 0, 0); border: 0px; cursor: wait;"
    >
      <div class="blockui" style="margin-left:-93.5px;">
        <span>Dalam proses...</span>
        <span>
          <div class="kt-spinner kt-spinner--v2 kt-spinner--primary"></div>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
