<template>
  <div>
    <base-header base-title="Gallery"></base-header>
    <!-- begin:: Content -->
    <div
      class="kt-content kt-grid__item kt-grid__item--fluid"
      id="kt_content"
    >
      <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="row mb-5">
          <div class="col-md-12">
            <button
              type="button"
              class="btn btn-brand btn-sm btn-bold btn-upper float-right"
              @click="showModal()"
            ><i class="flaticon2-add-1"></i> Tambah Gambar</button>
          </div>
        </div>
        <div class="row">
          <div
            class="col-xl-3"
            v-for="item in dataPhoto"
            :key="item.id"
          >
            <div class="kt-portlet kt-portlet--contain">
              <div class="kt-blog-grid">
                <div class="kt-blog-grid__head">
                  <a
                    @click="trigger(item.id)"
                    class="kt-blog-grid__thumb-link"
                  >
                    <img
                      v-if="item.alias"
                      :src="baseUrl + '/gallery/api/image/alt/' + item.alias"
                      class="kt-blog-grid__image"
                    >
                    <div
                      class="text-center h1"
                      v-else
                    >
                      <i class="la la-file-photo-o"></i>
                    </div>
                  </a>
                </div>
                <div class="kt-portlet__body">
                  <div class="kt-blog-grid__body">
                    <div class="kt-blog-grid__date">
                      {{ item.taken | moment }}
                    </div>
                    <h2 class="kt-blog-grid__title">
                      {{ item.title ? item.title : 'No Title' }}
                    </h2>
                    <div class="kt-separator kt-separator--space-xs kt-separator--border-dashed"></div>
                    <div class="kt-blog-grid__content">
                      <div class="d-flex h-100">
                        <div class="my-auto"><small>{{ item.width && item.height ? item.width + ' x ' + item.height : "No size" }}</small></div>
                        <div class="ml-auto">
                          <btn-action
                            @event="reloadPage"
                            @edit="showModal"
                            :edit-id="item.id"
                          ></btn-action>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="row"
          v-if="loadMore"
        >
          <div class="col-md-12 text-center py-5">
            <div
              class="spinner-grow text-primary"
              role="status"
            >
              <span class="sr-only">Show more...</span>
            </div>
          </div>
        </div>
      </div>
      <form-modal
        v-if="isShowModal"
        :show-modal.sync="isShowModal"
        @event="reloadPage"
        :modal-name="modalName"
        :modal-data="dataEdit"
      ></form-modal>
    </div>
    <!-- end:: Content -->
  </div>
</template>

<script>
import CheckPermission from "./../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import AutoRefresh from "./../_general/AutoRefresh";
import BaseTitle from "./../_base/BaseTitle";
import BaseHeader from "./../_base/BaseHeader";
import BaseButtonModal from "./../_base/BaseButtonModal";
import DataTables from "./../_table/DataTables";
import BtnAction from "./_components/BtnAction";
import LoadSpinner from "./../_general/LoadSpinner";
import { RepositoryFactory } from "./../../repositories/RepositoryFactory";
const PhotoRepository = RepositoryFactory.get("photo");

var parse = require('parse-link-header');

export default {
  components: {
    AutoRefresh,
    DataTables,
    BaseTitle,
    BaseHeader,
    BtnAction,
    BaseButtonModal,
    FormModal: () => ({
      component: new Promise(function (resolve, reject) {
        setTimeout(function () {
          resolve(import(/* webpackChunkName: "modal-gallery" */ "./_components/FormModal.vue"));
        }, 1000);
      }),
      loading: LoadSpinner
    })
  },
  data() {
    var vx = this;
    return {
      baseUrl: process.env.VUE_APP_API_URL,
      pageShow: 0,
      lastPage: 0,
      dataPhoto: [],
      loadMore: true,
      isLoaded: false,
      isShowModal: false,
      dataEdit: null,
      headTitle: "Gallery",
      tableName: "table_gallery",
      modalText: "Tambah Gambar",
      modalName: "modal_gallery",
      modalIcon: "flaticon2-add-1"
    };
  },
  watch: {
    isShowModal: function (val) {
      if (!val) {
        this.dataEdit = null;
      }
    }
  },
  filters: {
    moment: function (date) {
      if (date) {
        return moment(date).format("DD MMMM YYYY | hh:mm:ss");
      }
      else {
        return "No Date";
      }
    }
  },
  methods: {
    showModal: function (data) {
      if (data !== undefined) {
        this.dataEdit = data;
      }
      this.isShowModal = true;
    },
    async trigger (id) {
    	await PhotoRepository.getPhoto(id)
        .then(response => {
          this.showModal(response.data);
        })
        .catch(error => {
        });
    },
    reloadPage: function (resolve) {
      this.pageShow = 0;
      this.dataPhoto = [];
      this.fetch(resolve);
    },
    async fetch(resolve) {
      var vx = this;
      PhotoRepository.getPhotoWOImg({ "sort": "id,DESC", "page": this.pageShow }).then(response => {
        var headersLink = parse(response.headers.link);
        vx.lastPage = headersLink.last.page;
        const data = response.data;
        $.each(response.data, function (key, value) {
          vx.dataPhoto.push(value);
          vx.loadMore = false;
        });

        if (resolve !== undefined) {
          resolve(data);
        }
        vx.loadMore = false;

      }).catch(error => {
        var msg = (error.message) ? error.message : error;
        this.showToastError('Photo : ' + msg);
        vx.loadMore = false;

      })
    },
    checkPermission: function (method) {
      return checkPermission.isCanAccess(method);
    },
    showToastError: function (message) {
      toastr.error(message);
    }
  },
  mounted() {
    this.fetch();
    var vx = this;
    $(window).scroll(function () {
      let tinggi = Math.floor($(window).scrollTop() + $(window).height())
      if (tinggi == $(document).height()) {
        if (vx.pageShow < vx.lastPage && vx.dataPhoto.length != 0) {
          vx.loadMore = true;
          vx.pageShow += 1;
          vx.fetch();
        }
      }
    });
  }
};
</script>